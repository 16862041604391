<template>
    <div class="for-male">
        <div class="desc">
            <p>
                <span class="c-primary f-bold">오늘 약속을 갑자기</span>
                잡고 싶을 때,
            </p>
            <p style="color: #434245">오늘만나로 만나보세요!</p>
        </div>
        <div class="center m-t-24 m-b-40">
            <img class="male-food" :src="require('@/assets/images/today-meeting/male-food@2x.png')" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActivateForMale',
}
</script>

<style scoped lang="scss">
.for-male {
    color: $grey-09;
    @include f-medium;

    .desc {
        font-size: 15px;
        padding: 0 16px;
    }
    .male-food {
        width: 65%;
        height: auto;
    }
}
</style>
